.calendar {
    max-width: 300px;
    margin-top: 30px;
    
    .calendar-header {
        width: 100%;
        display:  flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;

        >div {
            background: #007d99;
            color: #fff;
        }

        .prev, .next {
            width: 50px;
            text-align: center;
            cursor: pointer;
            line-height: 1.3em;
            padding-bottom: 5px;
        }

        .month {
            width: calc(~"100% - 102px");
            text-align: center;
            line-height: 1.3em;
            padding-bottom: 5px;
        }

        .weekdays {
            background: none;
            width: 100%;
            display: flex;
            flex-direction: row;

            >div {
                width: calc(~"100% / 7");
                color: #007d99;
                text-align: center;
                margin-top: 1px;
            }
        }
    }

    .calendar-body {
        background: none;
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;

        >div {
            width: calc(~"(100% / 7) - 1px");
            background: #eee;
            color: #007d99;
            text-align: center;
            margin-top: 1px;
        }
        
        .day {
            cursor: default;
            color: #333333;
            
            &.free-times {
                cursor: pointer;
                background-color: #d6edf0;
                color: #007d99;

                &:hover {
                    background: #007d99;
                    color: white;
                }
            }

            &.checked {
                background: #007d99;
                color: white;
            }
        }
    }
}

form.booking {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    .booking-alert {
        width: 100%;
        display: block;
        color: red;
    }

    .booking-success {
        width: 100%;
        display: block;
        color: green;
    }

    h1, h2, h3 {
        width: 100%;
    }

    input.checkbox {
        display: inline-block;
        margin-right: 5px;
    }

    ._checkbox {
        cursor: pointer;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    
        span {
            color: #333333;
        }
        
        input[type="checkbox"] {
            display: none;
        }
    
        
        &:hover {
            &:before {
                color: rgba(255,255,255,0.5);
            }
        }
    
        &.checked {
            &:before {
                color:#007d99;
            }
        }
    
        &:before {
            width: 32px;
            height: 32px;
            content: "\2713";
            color: transparent;
            display: inline-block;
            background: #fff;
            margin-right: 10px;
            font-size: 22px;
            font-weight: bold;
            padding-left: 5;
            text-align: center;
            transition: 0.35s all;
            flex: 0 0 32px;
            padding-top: 2px;
            border: 2px solid #007d99;
        }
    }

    label {
        margin-right: 30px;
    }

    .form-group {
        width: 100%;

        &.w30 {
            width: calc(~"30% - 20px");
        }
        &.w50 {
            width: calc(~"50% - 20px");
        }
        &.w70 {
            width: calc(~"70% - 20px");
        }
    }

    button {
        background: #007d99;
        color: white;
        padding: 5px 10px;
        outline: 0;
        border: 0;
        width: 48%;
        margin-top: 30px;
    }

    .time {
        width: 100%;
        margin-top: 30px;

        select {
            min-width: 215px;
        }
    }

    .booking-hidden {
        display: none;
        width: 100%;
    }

}

form.booking_login {
    padding-bottom: 30px;
    
    button {
        background: #007d99;
        color: white;
        padding: 5px 10px;
        outline: 0;
        border: 0;
        width: 48%;
        margin-top: 10px;
    }

    a {
        display: block;
        margin-top: 10px;
    }
}

#content {
    position: relative;

    .login-wrapper {
        position: absolute;
        border-left: 1px solid #007d99;
        width: calc(38.5% + 50px);
        padding-left: 50px;
        top: 300px;
        right: 0px;
    }
}

a.btn {
    background-color: rgb(239, 239, 239) !important;
    text-decoration: none;
    color: inherit;

    &:hover {
        text-decoration: none;
    }
}

@media (max-width: 768px) {
    #content {
        .login-wrapper {
            top: 210px;
        }
    }
}

@media (max-width: 700px) {
    #content {
        .login-wrapper {
            position: relative;
            border-left: 0px solid #007d99;
            border-bottom: 1px solid #007d99;
            width: 100%;
            padding-left: 0px;
            top: 0px;
            right: unset;
        }
    }

    form.booking {
        .form-group {
            width: 100%;
    
            &.w30 {
                width: 100%;
            }
            &.w50 {
                width: 100%;
            }
            &.w70 {
                width: 100%;
            }
        }
    }
}